import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Script from 'next/script';
import dynamic from "next/dynamic";
import Head from 'next/head';
import Whatsapp from '../components/whatsapp-button';
import DelhiWhatsapp from '../components/delhi-whatsapp-button';
//const Whatsapp = dynamic(() => import("../components/whatsapp-button"));
//const DelhiWhatsapp = dynamic(() => import("../components/delhi-whatsapp-button"));
import { useRouter } from 'next/router';
import 'bootstrap-icons/font/bootstrap-icons.css';


export default function App({ Component, pageProps }) {

  const [isLoading, setIsLoading] = useState(false);
  //const [preLoading, setpreLoading] = useState(true);
  const router = useRouter();
   const metaConfig = {
   
    "/upsc-prelims-test-series": {
      "@context": "http://schema.org/",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "UPSC Prestorming Prelims Test Series",
          "item": "https://www.shankariasacademy.com/upsc-prestorming-prelims-test-series/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "UPSC Prefit Prelims Test Series",
          "item": "https://www.shankariasacademy.com/upsc-prefit-prelims-test-series/"
        }
      ]
    },
    "/upsc-prestorming-prelims-test-series": {
     "@context": "http://schema.org/",
	"@type": "BreadcrumbList",
	"itemListElement": [
		{
			"@type": "ListItem",
			"position": 1,
			"name": "UPSC Prelims Test Series 2025",
			"item": "https://www.shankariasacademy.com/upsc-prelims-test-series/"
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "UPSC Prefit Prelims Test Series",
			"item": "https://www.shankariasacademy.com/upsc-prefit-prelims-test-series/"
		}
	]
    },
    "/upsc-prefit-prelims-test-series": {
     "@context": "http://schema.org/",
	"@type": "BreadcrumbList",
	"itemListElement": [
		{
			"@type": "ListItem",
			"position": 1,
			"name": "UPSC Prelims Test Series 2025",
			"item": "https://www.shankariasacademy.com/upsc-prelims-test-series/"
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "UPSC Prestorming Prelims Test Series",
			"item": "https://www.shankariasacademy.com/upsc-prestorming-prelims-test-series/"
		}
	]
    },
    // Add more routes as needed
  };
  const defaultMeta = {
    "@context": "http://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "UPSC Admissions",
        "item": "https://www.shankariasacademy.com/upsc-admission-anna-nagar/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "TNPSC Admissions",
        "item": "https://www.shankariasacademy.com/tnpsc-admissions/"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "UPSC Prelims Test Series 2025",
        "item": "https://www.shankariasacademy.com/upsc-prelims-test-series/"
      }
    ]
  };
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  useEffect(() => {
    console.log(router.pathname.includes("/delhi/"), router.pathname);

    if (
      window.location.href.includes("/delhi/")
    ) {
      setIsLoading(true);
      console.log(window.location.href, "whatsapp");
    }
    
    else if (
      window.location.href.includes("/sch-test/")
    ) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [router.pathname]);
  const currentMeta = metaConfig[router.pathname] || defaultMeta;

  return (
    <>
 <Head> <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(currentMeta,null, "\t") .replaceAll("],\n\t\"", "],\n\n\t\"") }} />                </Head>
 
      {!isLoading &&
        <Head>
          {/* Favicon for browsers */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
       
{/* PNG Favicons */}
<link rel="icon" href="/favicon-32x32.png" type="image/png" sizes="32x32" />
<link rel="icon" href="/favicon-16x16.png" type="image/png" sizes="16x16" />
<script async src="https://www.googletagmanager.com/gtag/js?id=G-7BBP512TZQ"></script>
          
          <script type="text/javascript"
            dangerouslySetInnerHTML={{
          __html: `
             window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', 'G-7BBP512TZQ');
          `,
        }}
      />
          <Script type="text/javascript">
                {`(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "npo1kw2swe");
                `};
         </Script>
          <Script>
          {`
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1448994685363415');
fbq('track', 'PageView');
            `};
          </Script>
     
    
         
        </Head>
      }
      {isLoading &&

        <Head>
        
          {/* Favicon for browsers */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        
{/* PNG Favicons */}
<link rel="icon" href="/favicon-32x32.png" type="image/png" sizes="32x32" />
<link rel="icon" href="/favicon-16x16.png" type="image/png" sizes="16x16" />
         
            


        </Head>
      }


      <title>Shankar IAS Academy</title>

      <Component {...pageProps} />
      <ToastContainer
        theme='dark'
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      
       {!isLoading &&
         <Whatsapp /> 
      } 
      {isLoading &&
        <DelhiWhatsapp />
      }
    </>)
}
